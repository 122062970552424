import { METHODS } from 'constants.js';

// Creates and returns an object containing RTK Query endpoint builder functions for all HTTP methods
export const makeEndpointHelpers = builder => METHODS.reduce((acc, method) => {
    acc[method] = (urlFnOrStr, opts = {}) => {
        const { onBefore, ...rest } = opts;

        const isGet = method === 'GET';
        const withBody = !isGet && method !== 'DELETE';

        return builder[isGet ? 'query' : 'mutation']({
            query: args => {
                onBefore?.(args);

                const url = typeof urlFnOrStr === 'function' ? urlFnOrStr(args) : urlFnOrStr;

                if(withBody) {
                    let { body } = opts;
                    body = typeof body === 'function' ? body(args) : (body ?? args);
                    return { url, method, body };
                } else return { url, method };
            },
            ...rest,
        });
    };
    return acc;
}, {});

// Transforms base64 thumbnails responses to include `uri` key to use directly as img src
export const transformResponseArrayOfObjWithB64Imgs = (res, arrKeys) => res.map(obj => {
    const modifiedObj = arrKeys.reduce((acc, key) => {
        if(!obj[key]) acc[key] = null;
        else {
            const { content_base64, content_type, ...rest } = obj[key];
            acc[key] = { ...rest, uri: `data:${content_type};base64,${content_base64}`, content_type };
        }

        return acc;
    }, {});

    return { ...obj, ...modifiedObj };
});
