import { createSlice } from '@reduxjs/toolkit';
import API from 'api/index.js';

const initialState = { processVehicles: [] };

export const operatorProcessSlice = createSlice({
    name: 'operatorProcess',
    initialState,
    reducers: {},
    extraReducers: builder => {
        const { vehicleStatusProcess, car } = API.endpoints;

        builder
            .addMatcher(
                vehicleStatusProcess.matchFulfilled,
                (state, { payload }) => { state.processVehicles = payload; },
            )
            .addMatcher(car.matchFulfilled, (s, { payload, meta }) => {
                const { idCar } = meta.arg.originalArgs;

                const car = s.processVehicles.find(({ id }) => id === idCar);
                if(!car) return;

                const { status } = payload;

                if(status && status !== 'shop_process') s.processVehicles.splice(s.processVehicles.indexOf(car), 1);
                else Object.assign(car, payload);
            });
    },
});

export default operatorProcessSlice.reducer;
