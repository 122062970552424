import { createSlice } from '@reduxjs/toolkit';
import API from 'api/index.js';

const initialState = {
    incomingVehicles: [],
    incomingTransferVehicles: [],
};

export const operatorIncomingSlice = createSlice({
    name: 'operatorIncoming',
    initialState,
    reducers: {},
    extraReducers: builder => {
        const { vehicleStatusIncoming, vehicleStatusTransfer, car } = API.endpoints;

        builder
            .addMatcher(
                vehicleStatusIncoming.matchFulfilled,
                (state, { payload }) => { state.incomingVehicles = payload; },
            )
            .addMatcher(
                vehicleStatusTransfer.matchFulfilled,
                (state, { payload }) => { state.incomingTransferVehicles = payload; },
            )
            .addMatcher(car.matchFulfilled, (state, { payload, meta }) => {
                const { idCar } = meta.arg.originalArgs;
                const { status } = payload;

                const myCar = state.incomingVehicles.find(({ id }) => id === idCar);
                const transCar = state.incomingTransferVehicles.find(({ id }) => id === idCar);

                if(!myCar && !transCar && status !== 'shop_transit' && status !== 'shop_transfer') return;

                if(!myCar) {
                    if(status === 'shop_transit') state.incomingVehicles.push(payload);
                } else {
                    if(status === 'shop_transit') Object.assign(myCar, payload);
                    else state.incomingVehicles.splice(state.incomingVehicles.indexOf(myCar), 1);
                }

                if(!transCar) {
                    if(status === 'shop_transfer') state.incomingTransferVehicles.push(payload);
                } else {
                    if(status === 'shop_transfer') Object.assign(transCar, payload);
                    else state.incomingTransferVehicles.splice(state.incomingTransferVehicles.indexOf(transCar), 1);
                }
            });
    },
});

export default operatorIncomingSlice.reducer;
