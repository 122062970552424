import { t } from 'ttag';

export const ErrorMessages = () => ({
    // TRANS: Error messages (usually shown in top notification bar)
    GENERAL_ERROR: t`GENERAL_ERROR`,
    NETWORK_ERROR: t`NETWORK_ERROR`,
    USER_WAS_NOT_VERIFIED: t`USER_WAS_NOT_VERIFIED`,
    INCORRECT_CREDENTIALS: t`INCORRECT_CREDENTIALS`,
    EMAIL_TAKEN: t`EMAIL_TAKEN`,
    EMAIL_INVALID: t`EMAIL_INVALID`,
    USER_NOT_FOUND: t`USER_NOT_FOUND`,
    ROUTE_NOT_FOUND: t`ROUTE_NOT_FOUND`,
    REFRESH_TOKEN_EXPIRED: t`ERR_MSG_SESSION_EXPIRED`,
    REFRESH_TOKEN_DATA_INVALID: t`ERR_MSG_SESSION_EXPIRED`,
    TRAFICOM_BROKEN: t`ERR_MSG_TRAFICOM_BROKEN`,
    TRAFICOM_API_EXCEPTION: t`ERR_MSG_TRAFICOM_BROKEN`,
    TRAFICOM_API_PERMISSION_DENIED: t`TRAFICOM_API_PERMISSION_DENIED`,
    TRAFICOM_API_OWNER_RESTRICTED: t`TRAFICOM_API_OWNER_RESTRICTED`,
    'Refresh token is expired': t`ERR_MSG_SESSION_EXPIRED`,
    'Refresh token data is invalid': t`ERR_MSG_SESSION_EXPIRED`,
    'Token is expired!': t`ERR_MSG_SESSION_EXPIRED`,
    ERROR_USERNAME_ALREADY_EXISTS: t`ERROR_USERNAME_ALREADY_EXISTS`,
});

export const SuccessMessages = () => ({
    // TRANS: Success messages (usually shown in top notification bar)
    GENERAL_SUCCESS: t`GENERAL_SUCCESS`,
    PROFILE_UPDATED: t`PROFILE_UPDATED`,
    ADDRESS_CREATED: t`ADDRESS_CREATED`,
    ADDRESS_UPDATED: t`ADDRESS_UPDATED`,
    ADDRESS_DELETED: t`ADDRESS_DELETED`,
});
