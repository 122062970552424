import { Link } from 'react-router-dom';
import { t } from 'ttag';
import imgLogo from 'static/logo.png';
import imgAutokierratys from 'static/Common/autokierratys-logo.png';
import LanguageSwitcher from 'components/LanguageSwitcher/index.js';
import './index.scss';

const PersonNavigator = () => (
    <nav className='person-navigator'>
        <div className='logo-container'>
            <Link to='/person' className='nav-logo-wrap'>
                <img src={imgLogo} alt='DELV' />
            </Link>
            <a href='https://autokierratys.fi/' target='_blank' rel='noopener noreferrer' className='nav-logo-wrap'>
                <img src={imgAutokierratys} alt='Suomen Autokierrätys' />
            </a>
        </div>
        <LanguageSwitcher />
        <Link to='/logout' className='logout'>
            {t`LOGOUT`}
        </Link>
    </nav>
);

export default PersonNavigator;
