import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { forceLogout } from 'utils.js';
import API from 'api/index.js';
import layout from 'store/ducks/layout.js';
import operatorHome from 'store/ducks/operatorHome.js';
import operatorRecycle from 'store/ducks/operatorRecycle.js';
import operatorIncoming from 'store/ducks/operatorIncoming.js';
import operatorAvailable from 'store/ducks/operatorAvailable.js';
import operatorProcess from 'store/ducks/operatorProcess.js';
import operatorUserManagement from 'store/ducks/operatorUserManagement.js';
import auth from 'store/ducks/auth.js';
import personRecycle from 'store/ducks/personRecycle.js';

const combinedReducer = combineReducers({
    [API.reducerPath]: API.reducer,
    auth,
    layout,
    operatorHome,
    operatorRecycle,
    operatorIncoming,
    operatorAvailable,
    operatorProcess,
    operatorUserManagement,
    personRecycle,
});

export default configureStore({
    preloadedState: window.___INITIAL_STATE__,
    reducer: (state, action) => {
        if(API.endpoints.logout.matchFulfilled(action) || API.endpoints.logout.matchRejected(action)) {
            forceLogout();
            return undefined;
        }

        return combinedReducer(state, action);
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(API.middleware),
});
