import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import LoginModal from 'components/LoginModal/index.js';
import './index.scss';

const RequireAuth = ({ type, redirectTo = '/login/person', children }) => {
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const userType = useSelector(state => state.auth.userType);
    const isExpired = useSelector(state => state.auth.isExpired);
    const from = useLocation();

    if(isExpired) {
        return (
            <>
                <LoginModal type={type} />
                <div className='inert-wrap' inert='inert'>
                    {children}
                </div>
            </>
        );
    }

    if(!isAuthenticated || type !== userType) {
        return <Navigate to={redirectTo} state={{ from }} replace />;
    }

    return children;
};

export default RequireAuth;
