import { Workbox } from 'workbox-window';
import { t } from 'ttag';
import API from 'api/index.js';
import { notify } from 'store/ducks/layout.js';
import {
    SW_PUSH_PUBLIC_KEY,
    SW_SET,
    SW_REGISTER_SUBSCRIPTION_SUCCESS,
    SW_REGISTER_SUBSCRIPTION_REQUEST,
    SW_REGISTER_SUBSCRIPTION_FAILURE,
    SW_PUBLIC_KEY_REQUEST,
    SW_PUBLIC_KEY_FAILURE,
} from './reducers.js';

let store = null;

const R_UNDERSCORE_G = /_/g;
const R_DASH_G = /-/g;

export const urlB64ToUint8Array = base64String => {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);

    const base64 = (base64String + padding)
        .replace(R_DASH_G, '+')
        .replace(R_UNDERSCORE_G, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for(let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }

    return outputArray;
};

const CALL_API = Symbol('CALL_API');

export const getPublicKey = () => new Promise((resolve, reject) => {
    console.error('TODO: API.push');

    return;

    // eslint-disable-next-line no-unreachable
    store.dispatch({
        [CALL_API]: {
            endpoint: API.push,
            authenticated: false,
            showSuccess: false,
            showError: false,
            method: 'GET',
            types: [SW_PUBLIC_KEY_REQUEST, SW_PUSH_PUBLIC_KEY, SW_PUBLIC_KEY_FAILURE],
        },
    }).then(() => {
        resolve(store.getState().ServiceWorker.push.public_key);
    }).catch(err => {
        reject(err);
    });
});

export const registerSubscription = subscription => new Promise((resolve, reject) => {
    console.error('TODO: API.push');

    return;

    // eslint-disable-next-line no-unreachable
    store.dispatch({
        [CALL_API]: {
            endpoint: API.push,
            authenticated: store.getState().Auth.isAuthenticated,
            method: 'POST',
            showSuccess: false,
            showError: false,
            body: { subscription: JSON.stringify(subscription) },
            types: [
                SW_REGISTER_SUBSCRIPTION_REQUEST,
                SW_REGISTER_SUBSCRIPTION_SUCCESS,
                SW_REGISTER_SUBSCRIPTION_FAILURE,
            ],
        },
    }).then(() => {
        resolve();
    }).catch(err => {
        reject(err);
    });
});

export default function createServiceWorker(_store) {
    return; // TODO: "Upgrade app" -modal

    /* eslint-disable no-unreachable */
    if(!('serviceWorker' in navigator && process.env.NODE_ENV !== 'development')) return;

    store = _store;

    window.addEventListener('load', () => {
        const wb = new Workbox('/service-worker-custom.js');

        wb.addEventListener('waiting', () => {
            store.dispatch(notify({
                text: t`UPDATE_VERSION`,
                type: 'success',
                // options: { onConfirm: () => store.dispatch({ type: UPDATE_SW }) },
            }));

            let currentValue = store.getState().Layout.notificationModal.update;

            const handleChange = () => {
                const previousValue = currentValue;
                currentValue = store.getState().Layout.notificationModal.update;

                if(previousValue !== currentValue) {
                    wb.addEventListener('controlling', () => {
                        localStorage.clear();
                        window.location.reload();
                    });

                    wb.messageSW({ type: 'SKIP_WAITING' });
                }
            };

            store.subscribe(handleChange);
        });

        wb.register().then(async swRegistration => {
            store.dispatch({ type: SW_SET, serviceWorker: swRegistration });
        });
    });
}
