import { ClipLoader } from 'react-spinners';
import './index.scss';

/**
 * This loading component is shown during API requests
 */

const DataLoading = ({ show, children }) => (
    <>
        {show && (
            <div className='data-loading-wrapper'>
                <ClipLoader
                    sizeUnit='px'
                    size={65}
                    color='var(--primary-blue);'
                />
            </div>
        )}
        {children}
    </>
);

export default DataLoading;
