import { RouterProvider, createBrowserRouter, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { USER_TYPE, APP_NAME } from 'constants.js';
// Layout components (imported non-lazily)
import DataLoading from 'components/layouts/LoadingViews/DataLoading.js';
import CoreLayout from 'components/layouts/CoreLayout/index.js';
import RequireAuth from 'components/RequireAuth/index.js';
// Route definitions
import UnauthenticatedRoutes from './unauthenticated.js';
import PersonRoutes from './person.js';
import OperatorRoutes from './operator.js';
import 'styles/base.scss';

const formatRoutesForReactRouterDOM = obj => Object.entries(obj)
    .map(([path, { element: RouteElement, ...handle }]) => ({ path, element: <RouteElement />, handle }));

const isOperator = APP_NAME === 'OPERATOR';
const isPerson = APP_NAME === 'PERSON';
const isBoth = !isOperator && !isPerson;

export const ROUTES = [
    ...((isPerson || isBoth) ? [{
        element: (
            <RequireAuth type={USER_TYPE.PERSON} redirectTo='/login/person'>
                <CoreLayout isPerson />
            </RequireAuth>
        ),
        children: formatRoutesForReactRouterDOM(PersonRoutes),
    }] : []),
    ...((isOperator || isBoth) ? [{
        element: (
            <RequireAuth type={USER_TYPE.OPERATOR} redirectTo='/login/operator'>
                <CoreLayout isOperator />
            </RequireAuth>
        ),
        children: formatRoutesForReactRouterDOM(OperatorRoutes),
    }] : []),
    { element: <CoreLayout />, children: formatRoutesForReactRouterDOM(UnauthenticatedRoutes) },
    // Redirects
    {
        path: '/login',
        element: <Navigate to={isOperator ? '/login/operator' : '/login/person'} />,
    },
];

export const router = createBrowserRouter(ROUTES);

const PPBRouter = () => (
    <DataLoading show={useSelector(s => s.layout.loading)}>
        <RouterProvider router={router} />
    </DataLoading>
);

export default PPBRouter;
