import { lazy } from 'react';
import { t } from 'ttag';

// All routes that require the user to be logged in as an "Operator" to be accessed

// DO NOT IMPORT THESE IN ANY VIEWS. Use our own hooks, or ones from `react-router-dom` to do whatever you need.
/*
    'pathpattern': { // The URL path that some view should be rendered at
        element: Element, // The rendered view component. YOU MOST LIKELY WANT TO `lazy(() => import(''))` THIS
        getTitle: Function, // Function that returns a `t` -string
        ...rest // Any other values can be added, and are accessible in any components with the `useRouteHandle` -hook
    }
*/
const OperatorRoutes = {
    '/operator': {
        element: lazy(() => import('views/operator/Home/index.js')),
        getTitle: () => t`(BROWSER_TAB_TITLE)_OPERATOR_HOME`,
    },
    '/operator/select-shop': {
        element: lazy(() => import('views/operator/SelectShop/index.js')),
        getTitle: () => t`(BROWSER_TAB_TITLE)_OPERATOR_SELECT_SHOP`,
    },
    '/operator/recycle': {
        element: lazy(() => import('views/operator/Recycle/index.js')),
        getTitle: () => t`(BROWSER_TAB_TITLE)_OPERATOR_RECYCLE_VIN`,
    },
    '/operator/recycle/vinless': {
        element: lazy(() => import('views/operator/Recycle/Vinless/index.js')),
        getTitle: () => t`(BROWSER_TAB_TITLE)_OPERATOR_RECYCLE_VINLESS`,
    },
    '/operator/recycle/:id': {
        element: lazy(() => import('views/operator/Recycle/CarDescription/index.js')),
        getTitle: () => t`(BROWSER_TAB_TITLE)_OPERATOR_RECYCLE_DESC`,
    },
    '/operator/recycle/:id/owner': {
        element: lazy(() => import('views/operator/Recycle/Owner/index.js')),
        getTitle: () => t`(BROWSER_TAB_TITLE)_OPERATOR_OWNER_DESC`,
    },
    '/operator/recycle/:id/certificate': {
        element: lazy(() => import('views/operator/Recycle/Certificate/index.js')),
        getTitle: () => t`(BROWSER_TAB_TITLE)_OPERATOR_RECYCLE_CERT`,
    },
    '/operator/recycle/:id/remarks': {
        element: lazy(() => import('views/operator/Recycle/Remarks/index.js')),
        getTitle: () => t`(BROWSER_TAB_TITLE)_OPERATOR_RECYCLE_REMARKS`,
    },
    '/operator/recycle/:id/done': {
        element: lazy(() => import('views/operator/Recycle/Done/index.js')),
        getTitle: () => t`(BROWSER_TAB_TITLE)_OPERATOR_RECYCLE_DONE`,
    },
    '/operator/incoming': {
        element: lazy(() => import('views/operator/Incoming/index.js')),
        getTitle: () => t`(BROWSER_TAB_TITLE)_OPERATOR_INCOMING`,
    },
    '/operator/incoming/:id': {
        element: lazy(() => import('views/operator/Incoming/IncomingSingle.js')),
        getTitle: () => t`(BROWSER_TAB_TITLE)_OPERATOR_INCOMING_SINGLE`,
    },
    '/operator/profile': {
        element: lazy(() => import('views/operator/Profile/index.js')),
        getTitle: () => t`(BROWSER_TAB_TITLE)_OPERATOR_PROFILE`,
    },
    '/operator/available': {
        element: lazy(() => import('views/operator/Available/index.js')),
        getTitle: () => t`(BROWSER_TAB_TITLE)_OPERATOR_AVAILABLE`,
    },
    '/operator/process': {
        element: lazy(() => import('views/operator/Process/index.js')),
        getTitle: () => t`(BROWSER_TAB_TITLE)_OPERATOR_PROCESS`,
    },
    '/operator/process/:id': {
        element: lazy(() => import('views/operator/Process/ProcessSingle/index.js')),
        getTitle: () => t`(BROWSER_TAB_TITLE)_OPERATOR_PROCESS_DETAILS`,
    },
    '/operator/management': {
        element: lazy(() => import('views/operator/Management/index.js')),
        getTitle: () => t`(BROWSER_TAB_TITLE)_OPERATOR_MANAGEMENT`,
    },
    '/operator/responsibility': {
        element: lazy(() => import('views/operator/Responsibility/index.js')),
        getTitle: () => t`(BROWSER_TAB_TITLE)_OPERATOR_RESPONSIBILITY`,
    },
    '/operator/responsibility/stats': {
        element: lazy(() => import('views/operator/Responsibility/Stats/index.js')),
        getTitle: () => t`(BROWSER_TAB_TITLE)_OPERATOR_RESPONSIBILITY_STATS`,
    },
    '/operator/responsibility/directives': {
        element: lazy(() => import('views/operator/Responsibility/Directives/index.js')),
        getTitle: () => t`(BROWSER_TAB_TITLE)_OPERATOR_DIRECTIVES`,
    },
    '/operator/responsibility/economy': {
        element: lazy(() => import('views/operator/Responsibility/Economy/index.js')),
        getTitle: () => t`(BROWSER_TAB_TITLE)_OPERATOR_RESPONSIBILITY_ECONOMY`,
    },
    '/operator/responsibility/evaluation': {
        element: lazy(() => import('views/operator/Responsibility/Evaluation/index.js')),
        getTitle: () => t`(BROWSER_TAB_TITLE)_OPERATOR_RESPONSIBILITY_EVALUATION`,
    },
};

export default OperatorRoutes;
