import { createSlice } from '@reduxjs/toolkit';
import API from 'api/index.js';

const initialState = { description: {} };

export const operatorRecycleSlice = createSlice({
    name: 'operatorRecycle',
    initialState,
    reducers: {},
    extraReducers: builder => {
        const { vehicle, singleCar } = API.endpoints;

        builder
            .addMatcher(vehicle.matchFulfilled, (state, { payload }) => { state.description = payload; })
            .addMatcher(singleCar.matchFulfilled, (state, { payload }) => { state.description = payload; });
    },
});

export default operatorRecycleSlice.reducer;
