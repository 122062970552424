import moment from 'moment';
import 'moment/locale/fi';
import { useLocale as doUseLocale } from 'ttag';
import { BACKEND_DATETIME_FORMAT } from 'constants.js';

export const RADIAN = Math.PI / 180;

// TODO: This uses default locale, instead of user's locale
export const TODAY = moment();

export const getLocaleDateFormat = (forDatePicker = true, withTime = false) => {
    const dateFormat = moment.localeData().longDateFormat('L');
    const timeFormat = moment.localeData().longDateFormat('LT');

    // react-datepicker's <DatePicker /> -component handles DD, YYYY, and A differently than moment.
    // For the <DatePicker /> component, those tokens need to be turned into lowercase.
    // See: https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md

    if(forDatePicker) {
        return withTime
            ? `${dateFormat.replace('DD', 'dd').replace('YYYY', 'yyyy')} ${timeFormat.replace('A', 'a')}`
            : dateFormat.replace('DD', 'dd').replace('YYYY', 'yyyy');
    } else {
        return withTime
            ? `${dateFormat} ${timeFormat}`
            : dateFormat;
    }
};

export const fetchLocale = () => (
    localStorage.getItem('locale') ||
    navigator.languages?.[0] ||
    navigator.language ||
    'fi'
).slice(0, 2);

export const setLocale = (lang, noRefresh = false) => {
    localStorage.setItem('locale', lang);

    const finnishRelTimeI18N = (number, withoutSuffix, key, isFuture) => {
        let result = '';

        switch(key) {
            case 's': { return isFuture ? 'muutaman sekunnin' : 'muutama sekunti'; }
            case 'ss': {
                result = isFuture ? 'sekunnin' : 'sekuntia';
                break;
            }
            case 'm': { return isFuture ? 'minuutin' : 'minuutti'; }
            case 'mm': {
                result = isFuture ? 'minuutin' : 'minuuttia';
                break;
            }
            case 'h': { return isFuture ? 'tunnin' : 'tunti'; }
            case 'hh': {
                result = isFuture ? 'tunnin' : 'tuntia';
                break;
            }
            case 'd': { return isFuture ? 'päivän' : 'päivä'; }
            case 'dd': {
                result = isFuture ? 'päivän' : 'päivää';
                break;
            }
            case 'M': { return isFuture ? 'kuukauden' : 'kuukausi'; }
            case 'MM': {
                result = isFuture ? 'kuukauden' : 'kuukautta';
                break;
            }
            case 'y': { return isFuture ? 'vuoden' : 'vuosi'; }
            case 'yy': {
                result = isFuture ? 'vuoden' : 'vuotta';
                break;
            }
            default: { console.error('Unexpected `key` in `finnishRelTimeI18N`', key); break; }
        }
        result = `${number} ${result}`;
        return result;
    };

    const localeOptions = ({
        fi: {
            relativeTime: {
                future: '%s päästä',
                past: '%s sitten',
                s: finnishRelTimeI18N,
                ss: finnishRelTimeI18N,
                m: finnishRelTimeI18N,
                mm: finnishRelTimeI18N,
                h: finnishRelTimeI18N,
                hh: finnishRelTimeI18N,
                d: finnishRelTimeI18N,
                dd: finnishRelTimeI18N,
                M: finnishRelTimeI18N,
                MM: finnishRelTimeI18N,
                y: finnishRelTimeI18N,
                yy: finnishRelTimeI18N,
            },
        },
    })[lang];

    moment.locale(lang, localeOptions);
    moment.weekdays(true);
    doUseLocale(lang);

    if(!noRefresh) window.location.reload();
};

export const formatter = new Intl.NumberFormat(fetchLocale(), {
    style: 'currency',
    currency: 'EUR',
});

export const formatDateTime = dateTime => {
    const currentLocale = fetchLocale();

    try {
        const newDate = new Date(dateTime);
        const date = new Intl.DateTimeFormat(currentLocale, {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        }).format(newDate);
        const time = new Intl.DateTimeFormat(currentLocale, {
            hour12: false,
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
        }).format(newDate);
        const full = date.concat(' ', time);

        return {
            full,
            date,
            time,
        };
    } catch (e) {
        console.error(e);
    }
};

export const isValidBackendTime = str => moment(str, BACKEND_DATETIME_FORMAT).isValid();

export const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = () => resolve(btoa(reader.result));
    reader.onerror = () => reject(reader.error);
});

export const anyColorToHex = str => {
    const temp = document.createElement('i');
    temp.style.display = 'none';
    temp.style.color = str;
    document.body.appendChild(temp);

    const maybeColor = getComputedStyle(temp).getPropertyValue('color');
    temp.remove();
    if(maybeColor) {
        return maybeColor;
    } else {
        const varAsHex = getComputedStyle(document.documentElement).getPropertyValue(`--${str}`);

        if(varAsHex) {
            return varAsHex;
        } else {
            console.warn('Unexpected value passed to anyColorToHex()', str);
            return null;
        }
    }
};

// Returns true if the color in {hexOrRGB} string is in hex or rgb format, and dark
export const isColorDark = hexOrRGB => {
    let rgb = hexOrRGB.trim(); // getComputedValue returns strings with whitespace......................................
    let r = null;
    let g = null;
    let b = null;

    if(rgb.charAt(0) === '#') {
        rgb = parseInt(rgb.substring(1), 16);
        /* eslint-disable no-bitwise */
        r = (rgb >> 16) & 0xff;
        g = (rgb >> 8) & 0xff;
        b = (rgb >> 0) & 0xff;
        /* eslint-enable no-bitwise */
    } else {
        [r, g, b] = rgb.match(/\d+/g);
    }

    const luma = (0.2126 * r) + (0.7152 * g) + (0.0722 * b);

    return luma < 128;
};

export const formDataToJSON = (fd, stringify = true) => {
    const r = {};

    fd.forEach((v, k) => {
        if(r.hasOwnProperty(k)) { r[k] = Array.isArray(r[k]) ? r[k].push(v) : [r[k], v]; } else { r[k] = v; }
    });

    return stringify ? JSON.stringify(r) : r;
};

export const b64ToBlob = (b64Data, contentType, sliceSize) => {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for(let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for(let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
};

export const downloadHttpResponseFile = (res, name) => {
    // If you get errors relating to createObjectUrl, make sure your action has the 'responseType: blob'
    const link = document.createElement('a');
    link.setAttribute('href', window.URL.createObjectURL(res));
    link.setAttribute('target', '_blank');
    name && link.setAttribute('download', name);

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    link.remove();
};

export const arrayEquals = (a, b) => (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((v, i) => v === b[i])
);

export const numericSort = (a, b, key) => {
    let comparedA = a[key];
    let comparedB = b[key];
    const noValue = [null, undefined];

    if(noValue.includes(comparedA) && !noValue.includes(comparedB)) {
        comparedB = 1;
    } else if(!noValue.includes(comparedA) && noValue.includes(comparedB)) {
        comparedA = 1;
    } else if(noValue.includes(comparedB) && noValue.includes(comparedA)) return 0;

    return (comparedA - comparedB > 0 ? 1 : -1);
};

export const alphabeticSortNull = (a, b, key, order) => {
    // Compares all alphabetical values, if value is null it's set on the bottom
    // If you want to sort the nulls on top, move "* order" to outside of this function
    const comparedA = a[key];
    const comparedB = b[key];
    const noValue = [null, undefined];

    if(noValue.includes(comparedA) && !noValue.includes(comparedB)) return 1;
    else if(noValue.includes(comparedB) && !noValue.includes(comparedA)) return -1;
    else if(noValue.includes(comparedB) && noValue.includes(comparedA)) return 0;

    return comparedA.localeCompare(comparedB) * order;
};

export const roundNumeric = val => (((val === null) || (val === undefined)) ? null : +val.toFixed(1));

export const formatGrams = value => {
    if((value === null) || (value === undefined) || Number.isNaN(value)) return [null, null];

    if(value > 1000000) return [roundNumeric(value / 1000000), 't'];
    if(value > 1000) return [roundNumeric(value / 1000), 'kg'];
    return [roundNumeric(value), 'g'];
};

export const formatMeters = value => {
    if((value === null) || (value === undefined) || Number.isNaN(value)) return [null, null];

    if(value > 1000) return [roundNumeric(value / 1000), 'km'];
    return [roundNumeric(value), 'm'];
};

export const formatAddress = location => {
    if(!location) return '';

    const { address, city, postal, country } = location;

    return [address, city, postal, country].reduce((acc, cur, i, arr) => {
        if(cur) {
            const next = arr[i + 1];

            if(next) {
                if(next === postal) acc += `${cur} `;
                else acc += `${cur}, `;
            } else acc += cur;
        }

        return acc;
    }, '');
};

export const formatBytes = bytes => {
    const s = 1024;
    let u = 0;

    while(bytes >= s || -bytes >= s) {
        bytes /= s;
        u++;
    }

    return `${(u ? +bytes.toFixed(1) : bytes)}${'KMGTPEZY'[u - 1] || ''}B`;
};

export const getParsedLSItemOr = (key, fallback, isInt) => {
    const value = localStorage.getItem(key);

    if(!value) return fallback;

    let parsedValue = null;
    try {
        parsedValue = JSON.parse(value);
    } catch (e) {
        console.warn('Error parsing localStorage. Clearing localStorage.', e, localStorage, key, fallback, isInt);
        localStorage.clear();
    }

    if(isInt) {
        if(Number.isNaN(parsedValue) || parsedValue === undefined || parsedValue === null) return fallback;
        else return parseInt(parsedValue, 10);
    }

    return parsedValue;
};

export const red2GreenRange = (v, max) => `rgb(${255 - 255 * v / max}, ${255 * v / max}, 0)`;

export const SORT = Object.freeze({
    id: (a, b) => (a.id > b.id ? 1 : -1),
    statusUpdatedAt: (a, b) => (b.status_updated_at || '')
        .localeCompare(a.status_updated_at || '', undefined, { numeric: true }),
});

export const formatFileInBase64ForBackend = async file => ({
    name: file.name,
    content_type: file.type,
    content_base64: await toBase64(file),
});

export const forceLogout = () => {
    [
        'access_token',
        'refresh_token',
        'public_key',
        'shopId',
        'userType',
        'shopName',
    ].forEach(k => localStorage.removeItem(k));

    window.location.reload();
};

export const parseJWTFromLocalStorageAccessToken = () => {
    if(!localStorage.access_token) return null;

    try {
        return JSON.parse(
            decodeURIComponent(
                atob(localStorage.access_token
                    .split('.')[1]
                    .replaceAll('-', '+')
                    .replaceAll('_', '/'),
                )
                    .split('')
                    .map(c => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`)
                    .join(''),
            ),
        );
    } catch (e) {
        console.error('Couldn\'t parse `access_token` for user\'s groups', e);
    }
};
