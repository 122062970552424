import { createSlice } from '@reduxjs/toolkit';
import API from 'api/index.js';

const initialState = {
    car: {},
    sites: [],
    sitesSponsored: [],
    location: {},
};

export const personRecycleSlice = createSlice({
    name: 'personRecycle',
    initialState,
    reducers: {
        setLocation: (state, { payload }) => { state.location = payload; },
    },
    extraReducers: builder => {
        builder
            .addMatcher(API.endpoints.site.matchFulfilled, (state, { payload }) => {
                state.sitesSponsored = payload.nearest_shop_per_operator;
                state.sites = payload.remaining_shops;
            })
            .addMatcher(API.endpoints.siteSingle.matchFulfilled, (state, { payload }) => {
                const { id } = payload;
                const i = state.sites.findIndex(site => site.id === id);

                if(i !== -1) state.sites[i] = payload;
                else state.sites.push(payload);
            })
            .addMatcher(API.endpoints.vinSearch.matchFulfilled, (state, { payload }) => { state.car = payload; });
    },
});

export const { setLocation } = personRecycleSlice.actions;

export default personRecycleSlice.reducer;
