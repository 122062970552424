import '../CoreLayout/index.scss';

/**
 * This loading component used as a root wrapper for rendering empty page when we are loading a route.
 */

const RouteLoading = () => (
    <div className='core-wrapper' />
);

export default (RouteLoading);
