import { createSlice } from '@reduxjs/toolkit';
import API from 'api/index.js';

const initialState = { myAvailableCars: [] };

export const operatorAvailableSlice = createSlice({
    name: 'operatorAvailable',
    initialState,
    reducers: {},
    extraReducers: builder => {
        const { car } = API.endpoints;

        builder
            .addMatcher(car.matchFulfilled, (state, { meta }) => {
                const { idCar, status, shop_id } = meta.arg.originalArgs;

                const car = state.myAvailableCars.find(({ id }) => id === idCar);
                if(!car || (!status && !shop_id)) return;

                status &&
                status !== 'shop_listing' &&
                state.myAvailableCars.splice(state.myAvailableCars.indexOf(car), 1);

                shop_id &&
                shop_id !== car.shop_id &&
                state.myAvailableCars.splice(state.myAvailableCars.indexOf(car), 1);
            });
    },
});

export default operatorAvailableSlice.reducer;
