import { Suspense } from 'react';
import { t } from 'ttag';
import { Helmet } from 'react-helmet';
import { useMatches, Navigate, Outlet, useLocation } from 'react-router-dom';
import TopNotification from 'components/layouts/TopNotification/index.js';
import OperatorNavigator from 'components/layouts/OperatorNavigator/index.js';
import PersonNavigator from 'components/layouts/PersonNavigator/index.js';
import RouteLoading from 'components/layouts/LoadingViews/RouteLoading.js';
import CookieBanner from 'components/layouts/CookieBanner/index.js';
import useShallowEqualSelector from 'hooks/useShallowEqualSelector.js';
import './index.scss';

// Renders an <Outlet /> component from the react-router-dom library, so basically the view element of the current route
// Also wraps it in <React.Suspense fallback={...} />, which is displayed while the view is loading
const CoreLayout = ({ isOperator, isPerson }) => {
    const from = useLocation();
    const shopId = useShallowEqualSelector(state => state.operatorHome.shopId);
    const lang = useShallowEqualSelector(state => state.layout.lang);
    const { pathname, handle } = useMatches().find(match => match.handle?.getTitle) || {};
    const title = handle?.getTitle?.();

    // Tbh this should be a component like <RequireAuth /> but specifically for operators. Maybe later.
    if(isOperator && !shopId && pathname !== '/operator/select-shop') {
        return <Navigate to='/operator/select-shop' state={{ from }} />;
    }

    return (
        <>
            <Helmet htmlAttributes={{ lang }}>
                <title>
                    {title || ''}{t`TAB_TITLE_SUFFIX`}
                </title>
                <meta name='description' content={t`WEBSITE_META_DESCRIPTION`} />
            </Helmet>
            <CookieBanner />
            <div className={`core-wrapper ${isOperator ? 'is-operator' : ''} ${isPerson ? 'is-person' : ''}`}>
                <TopNotification />
                {isOperator && <OperatorNavigator />}
                {isPerson && <PersonNavigator />}
                <div className='core-content'>
                    <Suspense fallback={<RouteLoading />}>
                        <Outlet />
                    </Suspense>
                </div>
            </div>
        </>
    );
};

export default CoreLayout;
