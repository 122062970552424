import { lazy } from 'react';
import { t } from 'ttag';

// All routes that require the user to be logged in as a "Person" / "Owner" to be accessed

// DO NOT IMPORT THESE IN ANY VIEWS. Use our own hooks, or ones from `react-router-dom` to do whatever you need.
/*
    'pathpattern': { // The URL path that some view should be rendered at
        element: Element, // The rendered view component. YOU MOST LIKELY WANT TO `lazy(() => import(''))` THIS
        getTitle: Function, // Function that returns a `t` -string
        ...rest // Any other values can be added, and are accessible in any components with the `useRouteHandle` -hook
    }
*/
const PersonRoutes = {
    '/person': {
        element: lazy(() => import('views/person/Home/index.js')),
        getTitle: () => t`(BROWSER_TAB_TITLE)_PERSON_HOME`,
    },
    '/person/vin': {
        element: lazy(() => import('views/person/VinNumber/index.js')),
        getTitle: () => t`(BROWSER_TAB_TITLE)_PERSON_VIN`,
    },
    '/person/car/:vin': {
        element: lazy(() => import('views/person/CarDescription/index.js')),
        getTitle: () => t`(BROWSER_TAB_TITLE)_PERSON_CAR_DESCRIPTION`,
    },
    '/person/location': {
        element: lazy(() => import('views/person/CarLocation/index.js')),
        getTitle: () => t`(BROWSER_TAB_TITLE)_PERSON_CAR_LOCATION`,
    },
    '/person/store/:lat/:lng': {
        element: lazy(() => import('views/person/StoreLocation/index.js')),
        getTitle: () => t`(BROWSER_TAB_TITLE)_PERSON_STORE_LOCATION`,
    },
    '/person/store/:id': {
        element: lazy(() => import('views/person/StoreContact/index.js')),
        getTitle: () => t`(BROWSER_TAB_TITLE)_PERSON_STORE_CONTACT`,
    },
    '/person/history': {
        element: lazy(() => import('views/person/History/index.js')),
        getTitle: () => t`(BROWSER_TAB_TITLE)_PERSON_HISTORY`,
    },
};

export default PersonRoutes;
