import cn from 'classnames';
import './index.scss';

export const Input = ({ label, type, name, errors, className, maxLength, inputClassName = '', multiple, ...rest }) => (
    <label className={cn('form-label', className)}>
        {label}
        <div className='form-field'>
            <input
                type={type || 'text'}
                name={name}
                maxLength={maxLength || 100}
                className={`${inputClassName} ${errors?.[name]?.length ? 'invalid' : ''}`}
                {...rest}
            />
        </div>
        {!!errors && (
            <span className={`form-error ${errors?.[name]?.length ? 'has-error' : ''}`}>
                {errors[name] || ' '}
            </span>
        )}
    </label>
);
