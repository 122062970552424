import { t } from 'ttag';
import CookieConsent from 'react-cookie-consent';
import './index.scss';

const CookieBanner = ({ ...rest }) => (
    <CookieConsent
        disableStyles
        buttonText={t`COOKIE_CONSENT_BANNER_ACCEPT_LABEL`}
        buttonClasses='btn btn--green-s'
        {...rest}
    >
        {t`COOKIE_CONSENT_BANNER_BODY`}
    </CookieConsent>
);

export default CookieBanner;
