import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import API from 'api/index.js';
import { USER_TYPE } from 'constants.js';
import { getParsedLSItemOr, parseJWTFromLocalStorageAccessToken } from 'utils.js';

const initialState = {
    isAuthenticated: !!localStorage.getItem('access_token'),
    firstName: '',
    lastName: '',
    userType: getParsedLSItemOr('userType', USER_TYPE.UNAUTHENTICATED, true),
    isExpired: false,
    groups: parseJWTFromLocalStorageAccessToken()?.user.groups || [],
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        expiredTokenLogout: state => {
            state.isAuthenticated = false;
            state.isExpired = true;
        },
        dismissLoginModal: state => {
            state.isAuthenticated = false;
            state.isExpired = false;
            state.userType = USER_TYPE.UNAUTHENTICATED;
        },
    },
    extraReducers: builder => {
        // There is no "logout" reducer, because the root reducer in store/createStore.js calls `forceLogout()`
        // Which returns `undefined`, so that the whole root store is cleared, and the page is refreshed.

        const { login, returnResponse } = API.endpoints;
        builder
            .addMatcher(
                isAnyOf(
                    login.matchFulfilled,
                    returnResponse.matchFulfilled,
                ),
                (state, { meta, payload }) => {
                    const { originalArgs, endpointName } = meta.arg;
                    const { userType = (endpointName === 'returnResponse' ? USER_TYPE.PERSON : null) } = originalArgs;
                    const { access_token, refresh_token, public_key } = payload;

                    localStorage.setItem('access_token', access_token || null);
                    localStorage.setItem('refresh_token', refresh_token || null);
                    localStorage.setItem('public_key', public_key || null);
                    localStorage.setItem('userType', userType || null);

                    const parsed = parseJWTFromLocalStorageAccessToken();
                    state.firstName = parsed?.user?.first_name || '';
                    state.lastName = parsed?.user?.last_name || '';
                    state.groups = parsed?.user.groups || [];
                    state.isAuthenticated = true;
                    state.isExpired = false;
                    state.userType = userType;
                },
            );
    },
});

export const { expiredTokenLogout, dismissLoginModal } = authSlice.actions;

export default authSlice.reducer;

